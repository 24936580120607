// src/Services/axiosInstance.js
import axios from "axios";
import { setUnauthorized } from "../Store/slices/UserSlice";
import { store } from "../Store";
import toastr from "../Config/toastrConfig";

const authAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_AUTH_URL, // Authentication API base URL
  headers: {
    "Content-Type": "application/json"
  }
});

const flowAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_FLOW_URL, // Flow API base URL
  headers: {
    "Content-Type": "application/json"
  }
});

const addRequestInterceptor = (instance) => {
  instance.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("user-token");
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

const addResponseInterceptor = (instance) => {
  instance.interceptors.response.use(
    (response) => response.data,
    (error) => {
      const { response, message } = error;
      let errorMessage = "An error occurred"; // Default error message

      if (response) {
        const { status, data } = response;

        if (status === 401) {
          localStorage.clear();
          store.dispatch(setUnauthorized(true)); // Dispatch unauthorized action
          // Skip toastr for 401, handled by modal instead
        } else {
          // Extract error message from the response data if available
          errorMessage = data?.message || message;
          toastr.error(errorMessage);
        }
      } else if (message) {
        // Handle network errors or other errors without a response
        toastr.error(message);
      }

      return Promise.reject(error);
    }
  );
};

// Add interceptors to each instance
addRequestInterceptor(authAxiosInstance);
addResponseInterceptor(authAxiosInstance);

addRequestInterceptor(flowAxiosInstance);
addResponseInterceptor(flowAxiosInstance);

export { authAxiosInstance, flowAxiosInstance };
