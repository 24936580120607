import { flowAxiosInstance } from "./axiosInterceptors";

export const getCustomComponentsApi = async () => {
  try {
    const response = await flowAxiosInstance.get("/components/list-custom");
    return response;
  } catch (error) {
    console.error("Error fetching custom compoenents:", error);
    throw error;
  }
};

export const getCustomComponentTemplateApi = async () => {
  try {
    const response = await flowAxiosInstance.get("components/custom");
    return response;
  } catch (error) {
    console.error("Error fetching custom compoenent template:", error);
    throw error;
  }
};

export const deleteCustomComponentApi = async (componentId) => {
  try {
    const response = await flowAxiosInstance.delete(
      `/components/custom?componentId=${componentId}`
    );
    return response;
  } catch (error) {
    console.error("Error deleting component:", error);
    throw error;
  }
};

export const shareComponentApi = async (componentId, userIdtoShare) => {
  try {
    const response = await flowAxiosInstance.post(
      `/components/share-custom?componentId=${componentId}`,
      {
        userIdtoShare
      }
    );
    return response;
  } catch (error) {
    console.error("Error sharing component:", error);
    throw error;
  }
};

export const compileAndSaveComponentApi = async (payload) => {
  try {
    const response = await flowAxiosInstance.post(
      "/components/create-and-compile-custom",
      payload
    );
    return response;
  } catch (error) {
    console.error("Error compiling custom component:", error);
    throw error;
  }
};

export const updateCustomComponentApi = async (componentId, code) => {
  try {
    const response = await flowAxiosInstance.put(
      `components/custom?componentId=${componentId}`,
      {
        code
      }
    );
    return response;
  } catch (error) {
    console.error("Error updating custom component:", error);
    throw error;
  }
};
