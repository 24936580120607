import { FaCopy, FaShare, FaTrash } from "react-icons/fa";
import toastr from "../Config/toastrConfig";

const extractPlaceholders = (str, isImportedData) => {
  const regex = isImportedData ? /{(\w+)}|{\w*$/g : /{(\w+)}/g;
  const matches = [];
  let match = regex.exec(str);

  while (match !== null) {
    if (isImportedData) {
      matches.push(match[1]);
    } else {
      matches.push({ name: match[1], enabled: true, checked: false });
    }
    match = regex.exec(str);
  }

  return matches;
};

const updatePromptStates = (placeholders, setPromptStates) => {
  setPromptStates((prevPromptStates) => {
    const newPromptStates = [...prevPromptStates];
    placeholders.forEach((placeholder) => {
      const newPlaceholder = {
        name: placeholder,
        enabled: true,
        checked: false
      };
      if (
        !newPromptStates.some((state) => state.name === newPlaceholder.name)
      ) {
        newPromptStates.push(newPlaceholder);
      }
    });
    return newPromptStates;
  });
};

const removePromptStates = (placeholders, setPromptStates) => {
  setPromptStates((prevPromptStates) =>
    prevPromptStates.filter((state) => !placeholders.includes(state.name))
  );
};

const processImportedData = (
  importedData,
  setPromptStates,
  setFieldValues,
  setEditorContents,
  setNodes,
  setEdges,
  setNodeStateData,
  removeNode,
  handleChange,
  openPromptModal,
  handleParentFileDetailUpdate,
  updateId,
  duplicateNode,
  toggleMinimize,
  minimizedNodes,
  handleNodeUpdate,
  activeNodeId
) => {
  try {
    if (
      !Array.isArray(importedData) ||
      !importedData.every(
        (item) =>
          Object.prototype.hasOwnProperty.call(item, "id") &&
          Object.prototype.hasOwnProperty.call(item, "data")
      )
    ) {
      throw new Error("Invalid JSON structure");
    }

    const edges = importedData.flatMap((node) => node.edges || []);

    const nodes1 = importedData.map((node) => {
      const updatedParams = node.data.params
        ? Object.keys(node.data.params).reduce((acc, key) => {
            acc[key] = {
              ...node.data.params[key],
              default: node.fieldValues?.[key] || node.data.params[key].default
            };
            return acc;
          }, {})
        : {};

      return {
        ...node,
        data: {
          ...node.data,
          onDelete: removeNode,
          onChange: handleChange,
          params: updatedParams,
          dependentParams: node.data.dependent_params || {}, // Ensure dependent_params is included
          filesUploaded: node.files_uploaded || {}, // Ensure files_uploaded is included
          setEditorContents,
          openPromptModal,
          handleParentFileDetailUpdate,
          duplicateNode,
          toggleMinimize,
          minimizedNodes,
          handleNodeUpdate,
          activeNodeId
        }
      };
    });

    const newEditorContents = nodes1.reduce((acc, node) => {
      if (node.type === "conditional") {
        acc[node.id] = node.code || "";
      }
      return acc;
    }, {});

    const nodes = nodes1.map((node) => ({
      ...node,
      data: {
        ...node.data,
        editorContents: newEditorContents || {}
      }
    }));

    setEditorContents(newEditorContents);

    const maxId = Math.max(
      ...nodes
        .map((node) => parseInt(node.id.replace("dndnode_", ""), 10))
        .filter((num) => !Number.isNaN(num)) // Filter out NaN values
    );

    if (nodes?.length > 0) {
      updateId(maxId);
    }

    setNodes(nodes);
    setEdges(edges);

    const newNodeStateData = nodes.map((node) => ({
      id: node.id,
      label: node.name,
      name: node.name,
      state: node.data,
      type: node.type
    }));

    setNodeStateData(newNodeStateData);

    // Ensure the fieldValues are set
    const newFieldValues = nodes.reduce((acc, node) => {
      acc[node.id] =
        Object.keys(node.fieldValues)?.length > 0
          ? node.fieldValues // Use node.fieldValues if not empty
          : node.data.fieldValues || {}; // Otherwise, fall back to node.data.fieldValues
      return acc;
    }, {});
    setFieldValues(newFieldValues);

    // Extract and update prompt states
    const allPlaceholders = nodes.flatMap((node) =>
      Object.values(node.fieldValues || {}).flatMap((value) =>
        extractPlaceholders(value, true)
      )
    );
    updatePromptStates(allPlaceholders, setPromptStates);
  } catch (error) {
    toastr.error("Invalid JSON data");
    console.error("Invalid JSON data:", error);
  }
};

const disableScroll = () => {
  document.body.style.overflow = "hidden";
};

const enableScroll = () => {
  document.body.style.overflow = "";
};

const convertObjectToString = (obj, indent = 0) => {
  let result = "";

  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === "object" && obj[key] !== null) {
      result += `${" ".repeat(indent)}${key} :\n${convertObjectToString(
        obj[key],
        indent + 2
      )}`;
    } else {
      result += `${" ".repeat(indent)}${key} : ${obj[key]}\n`;
    }
  });

  return result;
};

const getToggledValue = (toggleField, userToToggle) => {
  if (toggleField === "role") {
    return userToToggle[toggleField] === "ADMIN" ? "USER" : "ADMIN";
  }

  return !userToToggle[toggleField];
};

// Function to stringify arrays and compare them
const isArraysEqual = (arr1, arr2) => {
  // Convert arrays to JSON strings
  const jsonStr1 = JSON.stringify(arr1);
  const jsonStr2 = JSON.stringify(arr2);

  // Compare the JSON strings
  return jsonStr1 === jsonStr2;
};

const getPythonCode = (tweeks, flowName, chatInputs) => {
  return `
from langflow import load_flow_from_json

TWEAKS = {
     ${JSON.stringify(tweeks)}
}

flow = load_flow_from_json("${flowName}.json", tweaks=TWEAKS)

# Now you can use it like any chain
inputs='${chatInputs}'

flow(inputs)`;
};

const getPythonApi = (tweeks, flowId) => {
  return `
import requests
from typing import Optional

BASE_API_URL = "${process.env.REACT_APP_BASE_URL}/api/v1/process"
FLOW_ID = "${flowId}"
# You can tweak the flow by adding a tweaks dictionary
# e.g {"OpenAI-XXXXX": {"model_name": "gpt-4"}}
TWEAKS = {
    ${JSON.stringify(tweeks)}
}

def run_flow(inputs: dict, flow_id: str, tweaks: Optional[dict] = None, api_key: Optional[str] = None) -> dict:
    """
    Run a flow with a given message and optional tweaks.

    :param message: The message to send to the flow
    :param flow_id: The ID of the flow to run
    :param tweaks: Optional tweaks to customize the flow
    :return: The JSON response from the flow
    """
    api_url = f"{BASE_API_URL}/{flow_id}"

    payload = {"inputs": inputs}
    headers = None
    if tweaks:
        payload["tweaks"] = tweaks
    if api_key:
        headers = {"x-api-key": api_key}
    response = requests.post(api_url, json=payload, headers=headers)
    return response.json()

# Setup any tweaks you want to apply to the flow
inputs = {"query":""}
api_key = "<your api key>"
print(run_flow(inputs, flow_id=FLOW_ID, tweaks=TWEAKS, api_key=api_key))`;
};

const getCurl = (tweeks, flowId) => {
  return `
  curl -X POST \
   ${process.env.REACT_APP_FLOW_URL}/${flowId} \
   -H 'Content-Type: application/json'\
   -H 'x-api-key: <your api key>'\
   -d '{"inputs": {"query":""}, "tweaks": {
     ${JSON.stringify(tweeks)}
 }}'`;
};

const getComponentActions = (handleShare, handleDelete) => [
  { label: "Share", icon: FaShare, onClick: handleShare },
  {
    label: "Delete",
    icon: FaTrash,
    onClick: handleDelete
  }
];

const getFlowActions = (handleDuplicate, handleDelete) => [
  { label: "Duplicate", icon: FaCopy, onClick: handleDuplicate },
  {
    label: "Delete",
    icon: FaTrash,
    onClick: handleDelete
  }
];

const transformComponentData = (rawData) => {
  const transformedData = {};

  Object.keys(rawData).forEach((category) => {
    const { icon, components } = rawData[category]; // Destructure icon and components from each category

    const transformedComponents = components.map((node) => {
      const { params, ...restNode } = node;

      const paramsSeparator = params.separator;
      // Transform separator param
      if (params && paramsSeparator) {
        paramsSeparator.default = paramsSeparator.default.replace("\n", "\\n");
      }

      // Transform params defaults to string using destructuring
      const transformedParams = Object.keys(params).reduce((acc, key) => {
        const { default: defaultValue, ...restParam } = params[key]; // Destructure default and other param properties
        acc[key] = {
          ...restParam,
          default: defaultValue?.toString() // Convert default to string
        };
        return acc;
      }, {});

      // Return the transformed node with destructuring
      return {
        ...restNode, // Include the rest of the node properties
        params: transformedParams, // Transformed params
        type: category === "Saved" ? "dynamic" : "custom", // Set component type based on category
        icon // Assign the icon from the category
      };
    });

    // Only include the category if it has components
    if (transformedComponents.length > 0) {
      transformedData[category] = {
        icon, // Category icon
        components: transformedComponents // Transformed components
      };
    }
  });

  return transformedData;
};

const generateChatWidgetScript = (selectedFlow, chatInputs, chatInputField) => {
  return `
  <script src="https://flexagents.aidevlab.com/bundle.min.js"></script>

  <!-- chat_inputs: Stringified JSON with all the input keys and its values. The value of the key that is defined
  as chat_input_field will be overwritten by the chat message.
  chat_input_field: Input key that you want the chat to send the user message with. -->

  <flex-agents-chat
    window_title="${selectedFlow.name}"
    flow_id="${selectedFlow.flowId}"
    chat_inputs='${chatInputs}'
    chat_input_field="${chatInputField}"
    host_url="${process.env.REACT_APP_FLOW_URL}"
  ></flex-agents-chat>`;
};

// keyPressUtils.js

const handleDeleteKeyPress = (activeNodeId, id, onDelete) => {
  const handleKeyPress = (event) => {
    if (event.key === "Delete" && activeNodeId === id) {
      onDelete(id); // Trigger node deletion
    }
  };

  document.addEventListener("keydown", handleKeyPress);

  return () => {
    document.removeEventListener("keydown", handleKeyPress);
  };
};

const truncateFileName = (name, charLength) => {
  return name.length > charLength
    ? `${name.substring(0, charLength)}...`
    : name;
};

export {
  extractPlaceholders,
  updatePromptStates,
  removePromptStates,
  processImportedData,
  disableScroll,
  enableScroll,
  convertObjectToString,
  getToggledValue,
  getPythonCode,
  getPythonApi,
  getCurl,
  getComponentActions,
  getFlowActions,
  transformComponentData,
  generateChatWidgetScript,
  isArraysEqual,
  handleDeleteKeyPress,
  truncateFileName
};
