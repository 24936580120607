import { flowAxiosInstance } from "./axiosInterceptors"; // Import the axios instance with interceptors

export const getFlowsApi = async () => {
  try {
    const response = await flowAxiosInstance.get("/flows/all"); // Assuming the login endpoint is '/login'
    return response; // The response is already transformed in the interceptor
  } catch (error) {
    console.error("Error during login:", error);
    throw error;
  }
};

export const removeFlowsApi = async (flowId) => {
  try {
    const response = await flowAxiosInstance.delete(`/flows/?flowId=${flowId}`); // Assuming the login endpoint is '/login'
    return response; // The response is already transformed in the interceptor
  } catch (error) {
    console.error("Error during login:", error);
    throw error;
  }
};
export const duplicateFlowsApi = async (flowId, newFlowId) => {
  try {
    const response = await flowAxiosInstance.post(
      `flows/duplicate?flowId=${flowId}&newFlowId=${newFlowId}`
    ); // Assuming the login endpoint is '/login'
    return response; // The response is already transformed in the interceptor
  } catch (error) {
    console.error("Error during login:", error);
    throw error;
  }
};

export const saveFlowsApi = async (flowId, flowName, flowData) => {
  try {
    const response = await flowAxiosInstance.post(
      `flows/save?flowId=${flowId}`,
      {
        flowName,
        graph: flowData.graph,
        required_outputs: flowData.required_keys
      }
    ); // Assuming the login endpoint is '/login'
    return response; // The response is already transformed in the interceptor
  } catch (error) {
    console.error("Error during login:", error);
    throw error;
  }
};

export const updateFlowsApi = async (flowId, flowName, flowData) => {
  try {
    const response = await flowAxiosInstance.put(`flows/?flowId=${flowId}`, {
      flowName,
      updateFlowData: flowData
    }); // Assuming the login endpoint is '/login'
    return response; // The response is already transformed in the interceptor
  } catch (error) {
    console.error("Error during login:", error);
    throw error;
  }
};

export const getSingleFlowDataApi = async (flowId) => {
  try {
    const response = await flowAxiosInstance.get(`flows/?flowId=${flowId}`); // Assuming the login endpoint is '/login'
    return response; // The response is already transformed in the interceptor
  } catch (error) {
    console.error("Error during login:", error);
    throw error;
  }
};

export const getComponentsApi = async () => {
  try {
    const response = await flowAxiosInstance.get("/flows/components"); // Assuming the login endpoint is '/login'
    return response; // The response is already transformed in the interceptor
  } catch (error) {
    console.error("Error during login:", error);
    throw error;
  }
};

export const buildFlowApi = async (payload) => {
  try {
    const response = await flowAxiosInstance.post(
      `/flows/compile-graph/?flowId=${payload.flowId}`,
      {
        graph: payload.graph,
        required_outputs: payload.required_outputs
      }
    ); // Assuming the login endpoint is '/login'
    return response; // The response is already transformed in the interceptor
  } catch (error) {
    console.error("Error during login:", error);
    throw error;
  }
};
