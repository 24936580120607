/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Navigate } from "react-router-dom";

const PublicRoute = ({ element: Element, restricted, ...rest }) => {
  const isAuthenticated = !!localStorage.getItem("user-token");

  return isAuthenticated && restricted ? (
    <Navigate to="/dashboard" />
  ) : (
    <Element {...rest} />
  );
};

export default PublicRoute;
/* eslint-disable react/jsx-props-no-spreading */
