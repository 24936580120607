import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getComponentsApi } from "../../Services/flowServices";
import { transformComponentData } from "../../Utils/helpers";
import {
  deleteCustomComponentApi,
  getCustomComponentTemplateApi
} from "../../Services/componentServices";

// Thunk for fetching components
export const fetchComponents = createAsyncThunk(
  "flows/fetchComponents",
  async () => {
    const response = await getComponentsApi();
    return transformComponentData(response.data); // Use the transformed data
  }
);

// Thunk for fetching the custom component template
export const fetchCustomComponentTemplate = createAsyncThunk(
  "flows/fetchCustomComponentTemplate",
  async () => {
    const { data } = await getCustomComponentTemplateApi();
    return data.template; // Return the template directly
  }
);

// Thunk for deleting a component
export const deleteComponent = createAsyncThunk(
  "flows/deleteComponent",
  async (componentId) => {
    await deleteCustomComponentApi(componentId);
    return componentId; // Return componentId to access in the reducer
  }
);

const initialState = {
  flowNodes: [],
  flowEdges: [],
  flowList: [],
  selectedFlow: null,
  activeNode: null,
  components: {}, // Store for components
  customComponentTemplate: "", // Store for the custom component template
  initialFlowElements: {
    isUpdated: false, // Track whether both arrays have been synced
    nodesInitialized: false, // Track if nodes have been set at least once
    edgesInitialized: false, // Track if edges have been set at least once
    nodes: [], // To store initial nodes
    edges: [] // To store initial edges
  },
  tweeks: {},
  tweeksParamNodes: [],
  loading: false, // Track loading state for async actions
  error: null
};

const FlowsSlice = createSlice({
  name: "flows",
  initialState,
  reducers: {
    setActiveNode: (state, action) => {
      state.activeNode = action.payload;
    },
    setFlowNodes: (state, action) => {
      state.flowNodes = action.payload;

      if (!state.initialFlowElements.isUpdated) {
        state.initialFlowElements.nodes = action.payload;
        state.initialFlowElements.nodesInitialized = true; // Mark that nodes have been set

        // Check if both nodes and edges have been set at least once
        if (
          state.initialFlowElements.nodesInitialized &&
          state.initialFlowElements.edgesInitialized
        ) {
          state.initialFlowElements.isUpdated = true; // Now mark as synced
        }
      }
    },
    setFlowEdges: (state, action) => {
      state.flowEdges = action.payload;

      if (!state.initialFlowElements.isUpdated) {
        state.initialFlowElements.edges = action.payload;
        state.initialFlowElements.edgesInitialized = true; // Mark that edges have been set

        // Check if both nodes and edges have been set at least once
        if (
          state.initialFlowElements.nodesInitialized &&
          state.initialFlowElements.edgesInitialized
        ) {
          state.initialFlowElements.isUpdated = true; // Now mark as synced
        }
      }
    },
    clearFlowState: (state) => {
      state.activeNode = null;
      state.flowNodes = [];
      state.flowEdges = [];
      state.flowList = [];
      state.initialFlowElements = {
        isUpdated: false,
        nodesInitialized: false,
        edgesInitialized: false,
        nodes: [],
        edges: []
      }; // Reset initialFlowElements
      state.error = null;
    },
    updateNodeFieldValue: (state, action) => {
      const { nodeId, key, value } = action.payload;
      const node = state.flowNodes.find((node) => node.id === nodeId);
      if (node) {
        if (!node.fieldValues) {
          node.fieldValues = {};
        }
        node.fieldValues[key] = value;
      }
    },
    setFieldValuesFromParams: (state, action) => {
      const { params } = action.payload;
      // Assuming params contains nodeId, key, and value
      params.forEach(({ nodeId, key, value }) => {
        const node = state.flowNodes.find((node) => node.id === nodeId);
        if (node) {
          if (!node.fieldValues) {
            node.fieldValues = {};
          }
          node.fieldValues[key] = value;
        }
      });
    },
    setFlowsError: (state, action) => {
      state.error = action.payload;
    },
    setFlowsList: (state, action) => {
      state.flowList = action.payload;
    },
    deleteFlowFromState: (state, action) => {
      state.flowList = state.flowList.filter(
        (flow) => flow.flow_id !== action.payload
      );
    },
    duplicateFlowInState: (state, action) => {
      state.flowList.push(action.payload);
    },
    updateFlowInState: (state, action) => {
      const { flowId, updatedFlow } = action.payload;
      const index = state.flowList.findIndex((flow) => flow.flowId === flowId);
      if (index !== -1) {
        state.flowList[index] = { ...state.flowList[index], ...updatedFlow };
      }
    },
    updateSelectedFlow: (state, action) => {
      state.tweeksParamNodes = state.flowNodes.filter(
        (node) => node.type === "custom" || node.type === "dynamic"
      );
      state.selectedFlow = action.payload;
      state.tweeks = state.flowNodes
        .filter((x) => x.type === "custom" || x.type === "dynamic")
        .reduce((obj, y) => {
          obj[y.name] = {};
          return obj;
        }, {});
    },
    updateNodeParams: (state, action) => {
      const { key, value, id } = action.payload;

      const nodeIndex = state.tweeksParamNodes.findIndex((x) => x.id === id);

      if (nodeIndex !== -1) {
        const node = state.tweeksParamNodes[nodeIndex];
        const { params, name } = node.data;

        if (params && params[key]) {
          params[key].value = value;

          if (!state.tweeks[name]) {
            state.tweeks[name] = {};
          }
          state.tweeks[name][key] = value;
        }
      }
    },
    addCustomComponent: (state, action) => {
      const newComponent = action.payload;

      // Check if state.components exists and has the 'Saved' key
      if (!state.components) {
        state.components = {}; // Initialize components if it doesn't exist
      }

      if (!state.components.Saved) {
        state.components.Saved = { components: [] }; // Initialize Saved if it doesn't exist
      }

      // Add the new component to the Saved array
      state.components.Saved.components.push({
        ...newComponent,
        type: "dynamic"
      });
    },
    updateCustomComponent: (state, action) => {
      const { componentId, updatedComponent } = action.payload;
      const savedCategory = state.components.Saved;

      if (savedCategory) {
        const index = savedCategory.components.findIndex(
          (component) => component.custom_component_id === componentId
        );
        if (index !== -1) {
          savedCategory.components[index] = {
            ...updatedComponent,
            type: "dynamic"
          };
        }
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchComponents.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchComponents.fulfilled, (state, action) => {
        state.loading = false;
        state.components = action.payload;
      })
      .addCase(fetchComponents.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchCustomComponentTemplate.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCustomComponentTemplate.fulfilled, (state, action) => {
        state.loading = false;
        state.customComponentTemplate = action.payload;
      })
      .addCase(fetchCustomComponentTemplate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteComponent.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteComponent.fulfilled, (state, action) => {
        state.loading = false;
        const componentId = action.payload;
        const savedCategory = state.components.Saved;
        // Check if the "Saved" category exists
        if (savedCategory) {
          savedCategory.components = savedCategory.components.filter(
            (component) => component.custom_component_id !== componentId
          );
        }
      })
      .addCase(deleteComponent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
});

export const {
  setActiveNode,
  setFlowNodes,
  setFlowEdges,
  clearFlowState,
  updateNodeFieldValue,
  setFieldValuesFromParams,
  setFlowsError,
  setFlowsList,
  deleteFlowFromState,
  duplicateFlowInState,
  updateFlowInState, // New action for updating a flow
  updateSelectedFlow,
  updateNodeParams,
  addCustomComponent,
  updateCustomComponent
} = FlowsSlice.actions;

export default FlowsSlice.reducer;
