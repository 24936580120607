import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getUsersApi,
  addNewUserApi,
  editUserApi,
  deleteUserApi,
  toggleUserRoleApi,
  updateAccountSettingsApi
} from "../../Services/authServices";

const initialState = {
  user: null,
  token: null,
  users: [],
  totalPages: 0,
  loading: false,
  error: null,
  unauthorized: false // State to handle unauthorized access
};

// Thunks for async actions
export const fetchUsers = createAsyncThunk(
  "user/fetchUsers",
  async ({ page, rowsPerPage }) => {
    try {
      const response = await getUsersApi({ page, rowsPerPage });
      return response;
    } catch (error) {
      console.error("Error fetching users:", error);
      throw error;
    }
  }
);

export const addUser = createAsyncThunk("user/addUser", async (newUser) => {
  const response = await addNewUserApi(newUser);
  return response;
});

export const editUser = createAsyncThunk(
  "user/editUser",
  async (editedUser) => {
    const response = await editUserApi(editedUser);
    return response;
  }
);

export const deleteUser = createAsyncThunk(
  "user/deleteUser",
  async (userId) => {
    const response = await deleteUserApi(userId);
    return response;
  }
);

export const toggleUserRole = createAsyncThunk(
  "user/toggleUserRole",
  async (payload) => {
    const response = await toggleUserRoleApi(payload);
    return response;
  }
);

export const updateProfileSettings = createAsyncThunk(
  "user/updateProfileSettings",
  async (payload) => {
    const response = await updateAccountSettingsApi(payload);
    return response;
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
    },
    logout: (state) => {
      state.user = null;
      state.token = null;
      localStorage.clear();
    },
    setUnauthorized: (state, action) => {
      state.unauthorized = action.payload; // Set unauthorized state
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload.users;
        state.totalPages = action.payload.totalPages;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(addUser.fulfilled, (state, action) => {
        state.users.push(action.payload.data);
      })
      .addCase(editUser.fulfilled, (state, action) => {
        const index = state.users.findIndex(
          (user) => user.id === action.payload.data.id
        );
        if (index !== -1) {
          state.users[index] = action.payload.data;
        }
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.users = state.users.filter((user) => user.id !== action.meta.arg);
      })
      .addCase(toggleUserRole.fulfilled, (state, action) => {
        const index = state.users.findIndex(
          (user) => user.id === action.payload.data.id
        );
        if (index !== -1) {
          state.users[index] = {
            ...state.users[index],
            ...action.payload.data
          };
        }
      })
      .addCase(updateProfileSettings.fulfilled, (state, action) => {
        state.user = { ...state.user, ...action.payload.data };
      });
  }
});

export const { setUser, logout, setUnauthorized } = userSlice.actions;
export default userSlice.reducer;
