import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import UserSlice, { logout } from "./slices/UserSlice";
import workflowSlice from "./slices/workflowSlice"; // Renamed for clarity
import ThemeSlice from "./slices/ThemeSlice";

const persistConfig = {
  key: "appRoot", // Updated key name for clarity
  storage
};

const appReducer = combineReducers({
  user: UserSlice,
  workflow: workflowSlice, // Updated to reflect the slice's purpose
  theme: ThemeSlice
});

const rootReducer = (state, action) => {
  if (action.type === logout.type) {
    // Clear the persisted storage when logging out
    storage.removeItem("persist:root");
    state = undefined; // Reset the entire Redux state
  }
  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
});

const persistor = persistStore(store);

export { store, persistor };
