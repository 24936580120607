import { useEffect } from "react";
import { useSelector } from "react-redux";

const ThemeProvider = ({ children }) => {
  const { mode } = useSelector((state) => {
    return state.theme;
  });

  useEffect(() => {
    document.body.classList.remove(mode === "dark" ? "light" : "dark");
    document.body.classList.add(mode);
  }, [mode]);

  return children;
};

export default ThemeProvider;
